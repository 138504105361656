<template>
  <div>
    <!-- eslint-disable max-len -->
    <el-navbar :isOpen="sideMenu.opened" @updateIsOpen="toggle">
      <!-- top part -->
      <!--   Logo   -->
      <router-link :to="{ name: logo.link,  }" class="el-navbar__item el-navbar__item__logo">
        <div :class="logo.icon"></div>
      </router-link>
      <el-navbar-spacer></el-navbar-spacer>
      <div @click="goOUT()" class="el-navbar__item" exact>
        <div :class="logout.icon"></div>
      </div>
    </el-navbar>
    <div class="el-body">
      <!-- aside menu -->

      <div class="el-panel">
        <el-toolbar :sticky="true">
          <h2 class="el-main-title">
            <span>{{ selected.name }}</span>
          </h2>

          <el-toolbar-spacer />
          <!-- search bar -->
        </el-toolbar>
        <div>
          <div>
            <el-table>
              <el-table-header>
                <el-table-row>
                  <!-- <el-table-head data-type="input">

                  <input class="el-checkbox" type="checkbox" />
                  </el-table-head>-->

                  <el-table-head>Type</el-table-head>
                  <el-table-head :main="true">{{ $t('documents.name') }}</el-table-head>
                  <el-table-head>{{ $t('documents.date') }}</el-table-head>
                  <el-table-head :main="true" data-type="button"></el-table-head>
                </el-table-row>
              </el-table-header>
              <el-table-body>
                <el-table-row
                  v-for="item in selected.children"
                  :key="item.name"
                  @click.native="$router.push({ name: 'guest.details', params: { item } })"
                >
                  <!-- <el-table-cell data-type="input">
                  <input class="el-checkbox" type="checkbox" />
                  </el-table-cell>-->
                  <el-table-cell>
                    <span :class="['icon-document', 'icon--table--orange']"></span>
                  </el-table-cell>
                  <el-table-cell :main="true">{{item.name}}</el-table-cell>
                  <el-table-cell>{{item.depositDate}}</el-table-cell>
                  <el-table-cell data-type="button" @click.native.stop>
                    <el-rectangle-button-container>
                      <el-rectangle-button
                        @click.native="downloadFile(item)"
                      >{{ $t('documents.toolbar.button.download') }}</el-rectangle-button>
                    </el-rectangle-button-container>
                  </el-table-cell>
                </el-table-row>
              </el-table-body>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import downloadjs from 'downloadjs';
// import EpLayout from '@/layouts/Default.vue';
//  import EpDocuments from '../documents/Index';
// import addCollecteurDialog from '@/pages/collectors/add.vue';
// v-for="(item, index) in documents"
//           :key="'documents_' + index"
//           :class="{ 'active': item.code === selectedPage }"
//           @click.nzzzzzative="goToFolder(item)"
//  <span :class="'icon-' + icons.find(x => x.code === item.code).value"></span>
//           <template
//             v-if="item.code === 'notClassified'"
//           >{{ $t('documents.categories.notClassified') }}</template>
//           <template v-else>{{ item.name }}</template>
//           <el-badge :notif="item.isNew" />
// :checked="allSelected"
//                   @click="selectAll()"
export default {
  data() {
    return {
      selected: {},
      myall: [],
      docs: [],
      show: false,
      logo: { link: 'guest.index', icon: 'icon-edocperso' },
      logout: { link: 'login.index', icon: 'icon-loggout' },
    };
  },
  computed: {
    ...mapState('application', {
      sideMenu: state => state.sideMenu,
      allShares: state => state.documents.allShares,
    }),
    ...mapGetters({
      allShares: 'documents/allShares',
    }),
  },
  methods: {
    ...mapActions({
      getSharings: 'documents/allShares',
      downloadMyFile: 'documents/downloadMyFile',
    }),
    toggle() {
      this.$store.dispatch('application/toggleSideMenu');
    },

    downloadFile(file) {
      this.downloadMyFile(file.id).then((response) => {
        let filename = file.name;
        if (!filename.endsWith(`.${file.extension}`)) {
          filename = `${filename}.${file.extension}`;
        }
        downloadjs(response, filename);
      });
    },
    goOUT() {
      this.$store.dispatch('auth/logoutGuest', this.user);
    },
  },
  mounted() {
    // this.getSharings().then((response) => {
    //   this.all = response.content;
    // });

    this.getSharings().then((response) => {
      this.myall = response;
      this.selected = Object.assign({}, this.myall[0]);
    });
  },
};
</script>
